/**
 * Created by Administrator on 2019/10/6.
 */
import { request } from '../utils/request'
import serviceInterface from '../api/serviceInterface'

/**
 * 登陆用户
 * @param params
 * @returns {*|Promise|Promise<unknown>|undefined}
 */
export const loginUser = (params) => {
    return request({
        url: serviceInterface.auth.login,
        params: params
    })
}

/**
 * 注册
 * @param params
 * @returns {*|Promise|Promise<unknown>|undefined}
 */
export const signUp = (params) => {
    return request({
        url: serviceInterface.auth.signUp,
        params: params
    })
}

/**
 * 重置密码
 * @param params
 * @returns {*|Promise|Promise<unknown>|undefined}
 */
export const resetPwd = (params) => {
    return request({
        url: serviceInterface.auth.resetPwd,
        params: params
    })
}

/**
 * 获取验证码
 * @param params
 * @returns {*|Promise|Promise<unknown>|undefined}
 */
export const getVerificationCode = (params) => {
    return request({
        url: serviceInterface.auth.getCode,
        params: params
    })
}

/**
 * 校验图片验证码
 * @param params
 * @returns {*|Promise|Promise<unknown>|undefined}
 */
export const checkCaptcha = (params) => {
    return request({
        url: serviceInterface.auth.checkCaptcha,
        params: params
    })
}


/**
 * 获取图片验证码
 * @param params
 * @returns {*|Promise|Promise<unknown>|undefined}
 */
export const getCaptcha = (params) => {
    return request({
        url: serviceInterface.auth.getCaptcha,
        params: params
    })
}

/**
 * 获取用户
 * @param params
 * @returns {*|Promise|Promise<unknown>|undefined}
 */
export const getSessionUser = (params) => {
    return request({
        url: serviceInterface.auth.getSessionUser,
        params: params
    })
}

/**
 * 邀请好友
 * @param params
 * @returns {*|Promise|Promise<unknown>|undefined}
 */
export const inviteFriends = () => {
    return request({
        url: serviceInterface.auth.inviteFriends
    })
}


/**
 * 邀请好友
 * @param params
 * @returns {*|Promise|Promise<unknown>|undefined}
 */
export const inviteRecords = (params) => {
    return request({
        url: serviceInterface.auth.inviteRecords,
        params: params
    })
}
/**
 * 绑定手机或邮箱
 * @param params
 * @returns {*|Promise|Promise<unknown>|undefined}
 */
export const BindAccount = (params) => {
    return request({
        url: serviceInterface.auth.bindAccount,
        params: params
    })
}

/**
 * 修改密码
 * @param params
 * @returns {*|Promise|Promise<unknown>|undefined}
 */
export const updatePwd = (params) => {
    return request({
        url: serviceInterface.auth.updatePwd,
        params: params
    })
}
